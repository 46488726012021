import {
    ActionType,
    globalFilesEntityId,
    IQuestion,
    LayoutSectionParentKey,
    nullEntityId,
    QuestionCategories,
    QuestionFilter,
    QuestionId,
    QuestionType,
    RegionId,
    RunningFeatures,
    TenantRegion,
    UserPreferences,
} from "@sp-crm/core";
import { GlobalClientSearchCriteria } from "components/dashboard/types";
import { isFeatureEnabled } from "components/feature";
import {
    AdvancedSearchEntityType,
    ClientSearchSort,
    ComposeMessageQuery,
    useGetFilesQuery,
    useGetLayoutSectionsQuery,
} from "generated/graphql";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { ApplicationState } from "store/state";
import { isAllowed, isAllowedInWholeTenant } from "util/permissions";
import { stableQueryOptions } from "util/requests";
import { useBridgeQuestions, useBridgeQuestionsForCurrentRegion } from "./bridge";
import { tenantSettings, userPreferences } from "./preferences";
import { usesMoreThanOneRegion } from "./regions";
import { getTenant, getUsers, getUserState } from "./selectors";
import {
    usersForAllRegionsAccordingToLoggedInUserPermissions,
    usersForRegionId,
} from "./users";

type RegionAspect = "default" | "community" | "reference";

export const useRegionId = (aspect: RegionAspect = "default"): RegionId | null =>
    useSelector((state: ApplicationState) => {
        const regionObject = state.region.regions.find(
            r => r.key === state.region.selectedRegion,
        );
        if (!regionObject) {
            return null;
        }
        if (aspect === "default") {
            return regionObject.id;
        }
        if (aspect === "community") {
            return regionObject.communityRegionId;
        }
        if (aspect === "reference") {
            return regionObject.referenceRegionId;
        }
        throw new Error(`Unknown region aspect: ${aspect}`);
    });

export const useIsMultiRegionUser = (): boolean =>
    useSelector((state: ApplicationState) => usesMoreThanOneRegion(state));

export const useRegions = () =>
    useSelector((state: ApplicationState) => state.region.regions);

export const regionIdFromState = (
    state: ApplicationState,
    aspect: RegionAspect = "default",
): RegionId => {
    const regionObject = state.region.regions.find(
        r => r.key === state.region.selectedRegion,
    );
    if (!regionObject) {
        return nullEntityId<RegionId>();
    }
    if (aspect === "default") {
        return regionObject.id;
    }
    if (aspect === "community") {
        return regionObject.communityRegionId;
    }
    if (aspect === "reference") {
        return regionObject.referenceRegionId;
    }
    return nullEntityId<RegionId>();
};

export const useRegionsWithPermission = (action: ActionType): TenantRegion[] =>
    useSelector((state: ApplicationState) => {
        const keys = state.permissions.permissions.regions
            .filter(r => r.permissions.includes(action))
            .map(r => r.regionKey);
        return state.region.regions
            .filter(r => keys.includes(r.key))
            .sort((a, b) => a.name.localeCompare(b.name));
    });

function renderable(question: IQuestion): boolean {
    return (
        question.questionType === QuestionType.binary ||
        question.questionType === QuestionType.nary
    );
}

function getFirstTypeFilterQuestion(questions: IQuestion[]): IQuestion {
    const order = (questionA: IQuestion, questionB: IQuestion): number =>
        (questionA.order || 0) - (questionB.order || 0);
    return (questions || [])
        .filter(q => q.category === QuestionCategories.type && renderable(q))
        .sort(order)[0];
}

export const useCommunityTypeQuestion = (
    regionKey: string,
    regionId: RegionId,
): IQuestion | null => {
    const questions = useBridgeQuestions(AdvancedSearchEntityType.Community);

    const regionQuestions = QuestionFilter.filter(questions, regionKey, regionId);

    const communityTypeQuestion = getFirstTypeFilterQuestion(regionQuestions);
    return communityTypeQuestion || null;
};

export const useResponsiveMode = () =>
    useSelector((state: ApplicationState) => state.responsive.mode);

export const useCommunityCardQuestionIds = (): QuestionId[] => {
    const layoutQuery = useGetLayoutSectionsQuery(
        {
            parentKey: LayoutSectionParentKey.CommunityCard,
        },
        stableQueryOptions(),
    );
    const layoutQuestionIds: QuestionId[] = [];
    for (const section of layoutQuery.data?.getLayoutSections?.layoutSections || []) {
        for (const item of section.layoutItems || []) {
            if (item && item.questionId) {
                layoutQuestionIds.push(item.questionId);
            }
        }
    }

    return useQuestionIdsWithUpstreamIds(layoutQuestionIds);
};

export const useQuestionIdsWithUpstreamIds = (
    originalQuestionIds: QuestionId[],
): QuestionId[] => {
    const regionId = useRegionId();
    const regions = useRegions();
    const region = regions.find(r => r.id === regionId);
    const regionKey = region?.key;

    const communityTypeQuestion = useCommunityTypeQuestion(regionKey, regionId);
    if (communityTypeQuestion) {
        originalQuestionIds = [...originalQuestionIds, communityTypeQuestion.id];
    }

    const questions = useBridgeQuestionsForCurrentRegion(
        AdvancedSearchEntityType.Community,
    );

    const requestedQuestionIds = useMemo(() => {
        const originalQuestions: IQuestion[] = [];
        const result = new Set<QuestionId>();

        for (const questionId of originalQuestionIds) {
            const question = questions.find(q => q.id === questionId);
            if (question) {
                originalQuestions.push(question);
                result.add(questionId);
            }
        }

        const upstreamQuestions = questions.filter(q =>
            originalQuestions.some(lq => !!lq.rules?.dependsOnKey(q.key)),
        );

        for (const question of upstreamQuestions) {
            result.add(question.id);
        }

        return Array.from(result);
    }, [questions, originalQuestionIds]);

    return requestedQuestionIds;
};

export const useIsAllowed = (action: ActionType) => {
    return useSelector((state: ApplicationState) =>
        isAllowed(action, state.permissions, state.region),
    );
};

export const useIsAllowedInWholeTenant = (action: ActionType) => {
    return useSelector((state: ApplicationState) =>
        isAllowedInWholeTenant(action, state.permissions),
    );
};

export const useTenantSettings = () => {
    return useSelector((state: ApplicationState) => tenantSettings(state));
};

export const useCurrentTenant = () =>
    useSelector((state: ApplicationState) => getTenant(state));

export const useFeature = (featureName: RunningFeatures) => {
    return useSelector((state: ApplicationState) => isFeatureEnabled(state, featureName));
};

export const useUsers = () => {
    return useSelector((state: ApplicationState) => getUsers(state));
};

export const useVisibleUsers = () => {
    return useSelector((state: ApplicationState) =>
        usersForAllRegionsAccordingToLoggedInUserPermissions(state),
    );
};

export const useUsersForRegion = (regionId: RegionId) => {
    return useSelector((state: ApplicationState) => usersForRegionId(state)(regionId));
};

export const useCurrentUserId = () => {
    return useSelector((state: ApplicationState) => getUserState(state).userId);
};

export const usePreferences = (): UserPreferences =>
    useSelector((state: ApplicationState) => userPreferences(state));

export const useCurrentUser = () =>
    useSelector((state: ApplicationState) =>
        state.userState.userId ? state.users.users[state.userState.userId] : null,
    );

export const useNow = (): Date => {
    return useSelector((state: ApplicationState) => state.time.now);
};

export type PipelineState = {
    searchCriteria: GlobalClientSearchCriteria;
    isReady: boolean;
    sortOrder: ClientSearchSort;
};

export const usePipelineState = () => {
    const loggedInUser = useCurrentUser();

    const searchString = useSelector(
        (state: ApplicationState) => state.clientSearch.clientSearchInstance.searchText,
    );
    const assignedUserId = useSelector((state: ApplicationState) =>
        state.clientSearch.clientSearchInstance.selectedUserId === "_"
            ? null
            : state.clientSearch.clientSearchInstance.selectedUserId,
    );
    const assignedReferralUserId = useSelector((state: ApplicationState) =>
        state.clientSearch.clientSearchInstance.referralSourceUserId === "_"
            ? null
            : state.clientSearch.clientSearchInstance.referralSourceUserId,
    );
    const searchType = useSelector(
        (state: ApplicationState) => state.clientSearch.clientSearchInstance.searchType,
    );
    const sort = useSelector(
        (state: ApplicationState) =>
            state.clientSearch.clientSearchInstance.cardSortPreference,
    );
    const regionId = useSelector(regionIdFromState);

    const searchCriteria = useMemo(() => {
        return {
            regionId,
            searchString,
            assignedUserId,
            assignedReferralUserId,
            sort,
            searchType,
            page: 1,
            perPage: 999,
        };
    }, [
        regionId,
        searchString,
        assignedUserId,
        assignedReferralUserId,
        sort,
        searchType,
    ]);

    return useMemo(() => {
        return {
            searchCriteria,
            isReady:
                !!loggedInUser &&
                searchCriteria.regionId &&
                searchCriteria.regionId !== nullEntityId<RegionId>(),
            sortOrder: sort,
        };
    }, [searchCriteria, loggedInUser, sort]);
};

export const useGlobalAttachmentScope = () => {
    const filesQuery = useGetFilesQuery({ entityId: globalFilesEntityId });

    const attachmentScopes: ComposeMessageQuery["composeMessage"]["files"] =
        useMemo(() => {
            if (Array.isArray(filesQuery.data?.getFiles.files)) {
                return [
                    { entityName: "Our files", files: filesQuery.data.getFiles.files },
                ];
            }

            return [];
        }, [filesQuery.data?.getFiles.files]);

    return attachmentScopes;
};

export const useCanSeeReferralSourceOwners = () => {
    const tenantSettings = useTenantSettings();
    const canSeeUsers = useIsAllowed(ActionType.LoadUsers);

    return !!(tenantSettings.ownedReferralSources && canSeeUsers);
};
