import { BrowserAgent } from "@newrelic/browser-agent/loaders/browser-agent";
import { getServerInfo } from "./server";

export type ErrorContext = Record<string, string | number | boolean | null>;

let instance: BrowserAgent;

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
const delayedContext: any[] = [];

export const configureErrorTracking = async () => {
    const serverInfo = await getServerInfo();
    if (!instance) {
        instance = new BrowserAgent({
            init: {
                distributed_tracing: { enabled: true },
                privacy: { cookies_enabled: true },
                ajax: { deny_list: ["bam.nr-data.net"] },
            },
            info: {
                beacon: "bam.nr-data.net",
                errorBeacon: "bam.nr-data.net",
                licenseKey: "NRJS-0c62406205be33949f9",
                applicationID: "1134538936",
                sa: 1,
            },
            loader_config: {
                accountID: "3483424",
                trustKey: "3483424",
                agentID: "1134538936",
                licenseKey: "NRJS-0c62406205be33949f9",
                applicationID: "1134538936",
            },
        });
        instance.setApplicationVersion(serverInfo.build);
        instance.setCustomAttribute("environment", serverInfo.environment);
    }

    delayedContext.forEach(x => x(instance));
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
(window as any).customReport = () => {
    try {
        throw "this is a test!";
    } catch (e) {
        instance.noticeError(e);
    }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
(window as any).customReportOnInstance = () => {
    try {
        throw "this is a test!";
    } catch (e) {
        instance.noticeError(e);
    }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
export const setErrorTrackingContext = (context: ErrorContext) => {
    const callback = (i: BrowserAgent) =>
        Object.keys(context).forEach(key => {
            i.setCustomAttribute(key, context[key]);
        });
    if (instance && instance.setCustomAttribute) {
        callback(instance);
    } else {
        delayedContext.push(callback);
    }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
export const reportError = (e: Error, context: ErrorContext) => {
    if (!instance) return;
    setErrorTrackingContext(context);
    instance.noticeError(e);
};
